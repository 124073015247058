import React, { useState } from "react";
import axios from "axios";
import configData from '../../../config.json'

const Newsletter = ()=>{
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [thankyouMSG, setThankyouMSG] = useState(false);
    const [errorMSG, setErrorMSG] = useState(false);
    const [formDataError, setFormDataError] = useState({
        email_id: false
    });
    const [formData, setFormData] = useState({
        email_id: ''
    });
    const handleInput = (e) => {
        setFormDataError({email_id: false});
        setThankyouMSG(false);
        setErrorMSG(false);
        setFormData({
            email_id: e.target.value
        });
    };
    const sendNewsLetter = () => {
        if(!emailRegex.test(formData.email_id)){
            setFormDataError({email_id: true});
            return false;
        }

        axios.request({
            method: 'post',
            url: configData.API_URL+'newsletter',
            data: formData,
            headers: {
                'Accept': "*/*",
                'Content-Type': "application/json"
            }
        }).then(resp => {
            setFormData({
                email_id: ''
            });
            setThankyouMSG(true);
            setTimeout(() => {
                setThankyouMSG(false);
            }, 3000);
        }).catch(error => {
            setThankyouMSG(false);
            setErrorMSG(true);
            setTimeout(() => {
                setErrorMSG(false);
            }, 3000);
        });
    };
    return (
        <>
            <section className="fluid stay-tuned-banner">
                <i className="icon-envelope"></i>
                <div className="container">
                    <div className="row">
                        <div className="news-subscription text-center">
                            <h2 className="heading-2">STAY TUNED WITH BYD</h2>
                            <p className="mb-40">Get the latest news, product updates and events updates</p>
                            <form className="news-subscription-form">
                                <div className="fieldset">
                                    <input type="email" value={formData.email_id} name="email_id" placeholder="Enter your email" onChange={handleInput}/>
                                    {formDataError.email_id && (<span className="red withError text-left">Please enter Email</span>)}
                                </div>
                                <button type="button" onClick={sendNewsLetter}>GET</button>
                                {thankyouMSG && (<div className="info" style={{color: 'green'}}>Newsletter subscription sent!</div>)}
                                {errorMSG && (<div className="info" style={{color: '#f00'}}>Something went wrong. Please try again later.</div>)}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Newsletter;