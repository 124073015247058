import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import configData from "../../../config.json";

const SliderWithTopCaption = ({ sliderId }) => {
  const [sliderData, setSliderData] = useState([]);
  const isMobile = window.innerWidth < 768;
  const settingsMultipleSlides = {
    dots: false,
    infinite: true,
    autoplaySpeed: 7000,
    autoplay: true,
    slidesToShow: 1,
    arrows: sliderData.length > 1,
    slidesToScroll: 1,
    speed: 500,
    fade: true,
  };

  const getSliderData = (id) => {
    axios
      .get(`${configData.API_URL}slider/${id}`)
      .then(function (response) {
        const filteredData = [];
        if (response.data.data && response.data.data.image_list) {
          response.data.data.image_list.map((item) => {
            filteredData.push({
              image:
                isMobile && item.mobileImage ? item.mobileImage : item.webImage,
              caption: item.caption,
              altText: item.subTitle,
            });
          });
        }
        setSliderData(filteredData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (sliderId) {
      getSliderData(sliderId);
    }
  }, [sliderId]);
  return (
    <section className="fluid bg-light-gray pt-10">
      <div className="cover-slides newmodel-carousel">
        {sliderData && (
          <Slider {...settingsMultipleSlides}>
            {sliderData.map((media, mIndex) => (
              <div key={`slider_item_5_${mIndex}`}>
                <div className="box">
                  <div className="newmodel-carousel">
                    <div
                      className="container mb-20 text-center"
                      dangerouslySetInnerHTML={{ __html: media.caption }}
                    ></div>
                  </div>
                  <div className="box-image">
                    <img
                      src={`${configData.IMAGE_BASE_URL}${media.image}`}
                      alt={media.altText}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default SliderWithTopCaption;
