import React from 'react'
import CountUp from 'react-countup';

const BydAttohomeimage = () => {
    return (
        <section className="fluid bg-light-gray-1">
            <div id="atto-banner">
                <div className="box-container Atto3box-container" style={{display: 'flex', flexWrap: 'nowrap'}}>
                    <div className="seal-box  seal-box-atto3">
                        <div className="box-content">
                        </div>
                    </div>
                    <div className="seal-box atto3-box1">
                        <div className="box-content">
                            <div className="box-countup Atto3box-countup">
                                <div className="subContent subAtto3"></div>
                                e-Platform <CountUp
                                    start={0}
                                    end={3}
                                    duration={3}
                                    decimals={1}
                                    redraw={true}
                                /></div>
                            <div className="subContent subAtto3">The Born EV e-Platform 3.0</div>
                        </div>
                    </div>
                    <div className="seal-box atto3-box1">
                        <div className="">
                            <div className="box-countup Atto3box-countup">

                                <CountUp
                                    start={0}
                                    end={7.3}
                                    duration={5}
                                    decimals={1}
                                    redraw={true}
                                />s</div>
                            <div className="subContent subAtto3">0 - 100 km/h*</div>
                        </div>
                    </div>
                    <div className="seal-box atto3-box1">
                        <div className="box-content">
                            <div className="box-countup Atto3box-countup">
                                <CountUp
                                    start={0}
                                    end={521}
                                    duration={3}
                                    decimals={0}
                                    redraw={true}
                                /> km</div>
                            <div className="subContent subAtto3">Top Driving range*(ARAI tested)</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BydAttohomeimage