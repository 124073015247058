import React, { useEffect, useState } from 'react';
import axios from "axios"
import dompurify from 'dompurify';
import configData from '../../../config.json'

const Career = () => {
	const [dataset, setDataSet] = useState([]);
    useEffect(() => {
        getDataSet();
        window.checkAccordElement();
    }, []);
    function getDataSet() {
        axios.get(configData.API_URL+'career').then(function(response) {
            const filteredData = response.data.data.filter(item => item.status === 'ACTIVE');
            setDataSet(filteredData);
        });
    }
	
  return (
    <>
    <section className="fluid bg-light-gray-1">
            <div className="container">
                <div className="row background-white pt-20 pb-20 mb-20 align-items-center">
                    <div className="col-xs-12 col-sm-12 col-md-12 pt-20 text-center">
                        <h2 className="heading-2">What we're looking for</h2>
                    </div>
                    <ul className="accordian-wrapper jobs-list">
                       {dataset.map((data, Index) =>
                            <li>
                                <a href="#" className="accord-toggler" data-index={Index+1}>                        
                                    <div>
                                        <p className="title">{data.position_name} <span>{data.division_name}</span></p>
                                        <p>{data.address}</p>
                                    </div>
                                    <span className="Job">View Job</span><i className="icon-arrow-down"></i>
                                </a>
                                <div className="accord-body" data-index={Index+1}>
                                    <div className="row">
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Roles & Responsibilities:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <div dangerouslySetInnerHTML={{__html:data.roles_responsibilities}}/>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Salary:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <p className="text-justify">{data.salary}</p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Experience:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <p>{data.experience}</p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Industry Preferred:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <p>{data.industry_preferred}</p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Qualification:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <p>{data.qualifications}</p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-2">
                                            <p className="text-end"><strong>Competencies:</strong></p>
                                        </div>
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-10">
                                            <p>{data.competencies}</p>
                                        </div>
                                    </div>	
                                </div>
                            </li>
                        )}
                    </ul>
                    <div className="col-xs-12">
                        <p>Mail us your CV at <b>careers.india@byd.com</b> and be sure to mention your preferred role and job location.</p>
                        <p><strong>Disclaimer:</strong><br/>At BYD INDIA our hiring process is based solely on merit. At no point in our recruitment process do we request candidates for monetary benefits from their end. Please beware of external fraudsters.</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};
  
export default Career;