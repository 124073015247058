import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import configData from '../../../config.json'
import { sortingASD } from '../../../util'

const Finddealer = () => {
    const [cmscontents_1, setCmscontents_1] = useState([]);
    const [filteredDealerList, setFilteredDealerList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [allCityList, setAllCityList] = useState({});
    const [dropDownState, setDropDownState] = useState('State');
    const [dropDownStateToggle, setDropDownStateToggle] = useState(false);
    const [dropDownCity, setDropDownCity] = useState('City');
    const [dropDownCityToggle, setDropDownCityToggle] = useState(false);
    const [resetFilter, setResetFilter] = useState(false);
    const stateRef = useRef();
    const cityRef = useRef();

    const handleClick = (event) => {
        if(stateRef.current && !stateRef.current.contains(event.target)){
            setDropDownStateToggle(false);
        }
        if(cityRef.current && !cityRef.current.contains(event.target)){
            setDropDownCityToggle(false);
        }
    };
    useEffect(() => {
        getCmscontents_1();
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, []);
	
	function getCmscontents_1() {
        axios.get(configData.API_URL+'dealers').then(function(response) {
            const data = response.data.data.filter(item => item.status === 'ACTIVE');
            data.sort(sortingASD);
            const state = [];
            const city = {};
            data.map(item => {
                state.push(item.state);
                if(city[item.state]){
                    if(!city[item.state].includes(item.city)){
                        city[item.state].push(item.city);
                    }
                }else{
                    city[item.state] = [];
                    city[item.state].push(item.city);
                }
            });
            const uniqueState = [];
            state.forEach(elem => {
                if(!uniqueState.includes(elem)) uniqueState.push(elem);
            });
            setStateList(uniqueState);
            setAllCityList(city);
            setCmscontents_1(data);
            setFilteredDealerList(data);
        });
    }
    const toggleStateDropDown = () => {
        setDropDownStateToggle(!dropDownStateToggle);
    }
    const toggleCityDropDown = () => {
        setDropDownCityToggle(!dropDownCityToggle);
    }
    const selectState = (item) => {
        toggleStateDropDown();
        setDropDownState(item);
        setResetFilter(true);
        setCityList(allCityList[item]);
        setDropDownCity('City');
        const filterData = cmscontents_1.filter(itm => itm.state === item);
        setFilteredDealerList(filterData);
    }
    const selectCity = (item) => {
        toggleCityDropDown();
        setDropDownCity(item);
        const filterData = cmscontents_1.filter(itm => itm.city === item);
        setFilteredDealerList(filterData);
    }
    const resetFilterMethod = () => {
        setResetFilter(false);
        setDropDownCity('City');
        setDropDownState('State');
        setCityList([]);
        setFilteredDealerList([...cmscontents_1]);
    };
  return (
    <>
    <section className="fluid background-white">
            <div className="container">
                <div className="row pv-30">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-30 text-center">
                        <div className="filterWrapper">
                            <div className="customDropDown" ref={stateRef}>
                                <div className="dropDownLabel" onClick={toggleStateDropDown}>
                                    <span>{dropDownState}</span>
                                    <i className="icon-arrow-down"></i>
                                </div>
                                <ul className={dropDownStateToggle ? 'active' : ''}>
                                    {stateList.map((item, index) => (
                                        <li key={index} data-index={index} onClick={() => selectState(item)}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="customDropDown" ref={cityRef}>
                                <div className="dropDownLabel" onClick={toggleCityDropDown}>
                                    <span>{dropDownCity}</span>
                                    <i className="icon-arrow-down"></i>
                                </div>
                                <ul className={dropDownCityToggle ? 'active' : ''}>
                                    {cityList.map((item, index) => (
                                        <li key={index} data-index={index} onClick={() => selectCity(item)}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            {resetFilter && (<button className="resetButton" type="button" onClick={resetFilterMethod}><i className="icon-close"></i></button>)}
                        </div>
                    </div>
                    {filteredDealerList.length && filteredDealerList.map(item => {
                        return (
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-30">
                                <div className="dealer-card">
                                    <div className="dealer-card-image">
                                        <img src={`${configData.IMAGE_BASE_URL}${item.thumbnail_image}`} className="img-fluid"/>
                                    </div>
                                    <div className="dealer-card-body">
                                        <div className="dealer-card-title">
                                            <p className="mb-0 heading-4 text-capitalize">{item.state}</p>
                                            <p className="mb-0 heading-3">{item.name}</p>
                                            <p className="mb-0">{item.address}</p>
                                        </div>
                                        <div className="dealer-card-contacts">
                                            <p className={`d-flex align-items-center ${item.alternative_phoneno.length ? "mb-0" : "mb-10"}`}>
                                                <i className="icon-phone"></i> {item.phone_no}
                                            </p>
                                            {(item.alternative_phoneno.length > 0) && (
                                                <p className="mb-10 d-flex align-items-center">
                                                    <i className="icon-phone"></i> {item.alternative_phoneno}
                                                </p>
                                            )}
                                            <p className="mb-0 d-flex align-items-center">
                                                <i className="icon-envelope"></i> {item.email}
                                            </p>
                                            {(item.alternative_email.length > 0) && (<p className="mb-0 d-flex align-items-center">
                                                <i className="icon-envelope"></i> {item.alternative_email}
                                            </p>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    </>
  );
};
  
export default Finddealer;