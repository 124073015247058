// ModalComponent.jsx
import React from 'react';

const ModalComponent = ({ isOpen, onClose }) => {
  return (
    <div className={`modal  ${isOpen ? 'show d-block' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">PUBLIC NOTICE</h5>
            <button type="button" className="btn-close"  aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body" style={{fontSize:14}}>
          In the interest of General public it is informed that unauthorized groups are offering dealerships of our Company / BYD India or job opportunities in BYD India through means of fake Facebook Account or other social media means. It is advised that an applicant proposing to take a dealership of BYD India or a candidate seeking job should TAKE NOTE and should not fall trap to any communication (be it verbal, written or otherwise) asking for money/benefit of any kind, in lieu of employment or Dealership of BYD India. BYD India does not require to pay or ask for any deposit from the job aspirants. Also, dealerships are offered by BYD based on various quality parameters, after verifications and proper evaluation of applicants. BYD does not require a proposed dealer to pay any money at the time of initial application for a Dealership.
          On receipt of a call or any communication on behalf of BYD India, the proposed Dealer or a candidate seeking job must take necessary measures to evaluate the authenticity of such communications such as by visiting the official website of BYD India Pvt. Ltd. i.e. <a href="http://www.bydautoindia.com" target="_blank" rel="noopener noreferrer">www.bydautoindia.com</a> to get the details verified.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
