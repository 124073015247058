import React, { useEffect, useState } from 'react';
import axios from "axios";
import Slider from "react-slick";
import configData from '../../../config.json';

const Events = () => {
    const [dataset, setDataSet] = useState([]);
    const [offset, setOffset] = useState(0);
    const [showMore, setShowMore] = useState(false);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const limit = 10;

    useEffect(() => {
        getDataSet(offset);
    }, []);

    function getDataSet(page) {
        axios.get(configData.API_URL + 'events', {
            params: {
                limit: limit,
                offset: page
            }
        }).then(function (response) {
            setShowMore(true);
            const sortedList = response.data.data.sort((a, b) => {
                const aDate = new Date(a.event_time);
                const bDate = new Date(b.event_time);
                return bDate - aDate;
            });
            setDataSet([...dataset, ...sortedList]);
        }).catch(function (error) {
            setShowMore(false);
        });
    }

    const loadEvent = () => {
        getDataSet(offset + limit);
        setOffset(offset + limit);
    };

    return (
        <>
            <section className="fluid">
                <div className="container">
                    <div className="row pv-30">
                        <div className="col-xs-12 col-sm-12 col-md-5">
                            <h2 className="mb-0 pdt-5 heading-2">Current Events</h2>
                        </div>
                    </div>
                    <div className="row">
                        {dataset.length > 0 && dataset.map((data, index) => {
                            if (data.image_list.length === 0) return null;
                            return (
                                <div key={`event_${index}`} className="col-xs-12 col-sm-12 col-md-12 col-lg-6 event-list pb-20 text-center">
                                    <div className="carousel-multiple-items-6">
                                        {data.image_list.length === 1 ? (
                                            <div className="box">
                                                <div className="box-image">
                                                    <img src={`${configData.IMAGE_BASE_URL}${data.image_list[0].url}`} className="img-fluid" alt={`Event ${index}`} />
                                                </div>
                                                <span className="f14l14"><strong>{data.title}</strong></span><br />
                                                <span className="f14l14"><strong>{data.event_time}</strong></span>
                                            </div>
                                        ) : (
                                            <Slider {...settings}>
                                                {data.image_list.map((media, mIndex) =>
                                                    <div key={`slider_${index}_${mIndex}`}>
                                                        <div className="box">
                                                            <div className="box-image">
                                                                <img src={`${configData.IMAGE_BASE_URL}${media.url}`} className="img-fluid" alt={`Event ${index}`} />
                                                            </div>
                                                            <span className="f14l14"><strong>{data.title}</strong></span><br />
                                                            <span className="f14l14"><strong>{data.event_time}</strong></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Slider>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {showMore && (
                        <div className="row pvb-30">
                            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                                <button type="button" className="load-more" onClick={loadEvent}>show more</button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <div className="popup-wrapper">
                <div className="popup-body">
                    <button className="close-popup">
                        <i className="icon-close"></i>
                    </button>
                    <img src="" className="img-fluid img-preview " alt="Preview" />
                </div>
            </div>
        </>
    );
};

export default Events;
