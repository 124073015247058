import React, { useState, useEffect } from "react";
import axios from "axios";
import configData from '../../config.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import  gtag  from 'ga-gtag';

const Header = () => {
    const [menuData, setMenuState] = useState([]);
    const [rightMenuData, setRightMenuData] = useState([]);
    const [thankyouMSG, setThankyouMSG] = useState(false);
    const [errorMSG, setErrorMSG] = useState(false);
    const [formDataError, setFormDataError] = useState({
        email_id: false,
        message: false
    });
    const [formData, setFormData] = useState({
        email_id: '',
        message: ''
    });

    const path = window.location.pathname.includes('/news/') ? '../' : './';
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // const iconsList = [
    //     (<i className="icon-location-pin"></i>),
    //     (<img src="/images/icon/icon-02.png" alt="Test Drive" />)
    // ];
    const getMenuContent = () => {
        axios.get(configData.API_URL + 'menu').then(response => {
            const centerMenu = response.data.data.find(item => item.sku === 'HEADER_CENTER_MENU');
            const rightMenu = response.data.data.find(item => item.sku === 'HEADER_RIGHT_MENU');
            if (centerMenu) {
                const bod = JSON.parse(centerMenu.body);
                bod.sort((a, b) => parseFloat(a.sortkey) - parseFloat(b.sortkey));
                setMenuState(bod);
            }
            if (rightMenu) {
                setRightMenuData(JSON.parse(rightMenu.body));
            }
        });
    };
    const handleMenuToggler = (e) => {
        e.preventDefault();
        let detailedMenu = document.querySelector('.detailed-menu');
        let menuDropdownWrapper = document.querySelector('.menu-dropdown-wrapper');
        if (detailedMenu.classList.contains('active')) {
            // window.closeFun();
            setTimeout(function () {
                detailedMenu.classList.add('menu-dropdown-show');
                menuDropdownWrapper.classList.remove('active');
            }, 500);
            setTimeout(function () {
                detailedMenu.classList.remove('menu-dropdown-show-active');
                detailedMenu.classList.remove('active');
            }, 800);
        } else {
            detailedMenu.classList.add('menu-dropdown-show');
            menuDropdownWrapper.classList.add('active');
            setTimeout(function () {
                detailedMenu.classList.add('menu-dropdown-show-active');
                detailedMenu.classList.add('active');
            }, 500);
        }
    };
    const handleMailTo = (e) => {
        e.preventDefault();
        let mailPopupWrapper = document.querySelector('.mail-popup-wrapper');
        if (mailPopupWrapper.classList.contains('show')) {
            mailPopupWrapper.classList.remove('show');
        } else {
            mailPopupWrapper.classList.add('show');
        }
        gtag('event', 'Email', {
            "section_name": "TopheaderEmail"
        });

    };

    const handleGlobalSearch = (e) => {
        e.preventDefault();
        window.searchPopup();
    };

    const handleInput = (e) => {
        setErrorMSG(false);
        setThankyouMSG(false);
        setFormDataError({ ...formDataError, [e.target.name]: false });
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSend = () => {
        if (!emailRegex.test(formData.email_id)) {
            setFormDataError({ ...formDataError, email_id: true });
            return false;
        }
        if (!formData.message) {
            setFormDataError({ ...formDataError, message: true });
            return false;
        }

        axios.request({
            method: 'post',
            url: configData.API_URL + 'contact-us',
            data: formData,
            headers: {
                'Accept': "*/*",
                'Content-Type': "application/json"
            }
        }).then(resp => {
            setFormData({
                email_id: '',
                message: ''
            });
            setThankyouMSG(true);
            setTimeout(() => {
                setThankyouMSG(false);
            }, 3000);
        }).catch(error => {
            setThankyouMSG(false);
            setErrorMSG(true);
            setTimeout(() => {
                setErrorMSG(false);
            }, 3000);
        });
    };

    const handleEnquiryClick = (item) => {
        if (item.label === 'Dealers') {
            gtag('event', 'Dealers', {
                "section_name": "Menu"
            });         
        } else if (item.label === 'Enquire Now') {
            gtag('event', 'Enquire Now', {
                "section_name": "Menu"
            });          
        }
    };
    
    const handleSupportGtag = () =>{       
        gtag('event', 'Support', {
            "section_name": "Top Header Menu"
        });    
    }
    const handleTelGtag = () =>{       
        gtag('event', 'Telephone', {
            "section_name": "Top Header menu"
        });
    }
    const handleTopheaderWhatsapp = () =>{       
        gtag('event', 'Whatsapp', {
            "section_name": "Top Header Menu"
        });
    }

    useEffect(() => {
        getMenuContent();
    }, []);
    return (
        <>
            <header className="fluid">
                <div className="fluid top-header">
                    <div className="container">
                        <div className="row">
                            <ul className="top-header-link">
                                <li className="">
                                    <a href="search" className="search-popup" onClick={handleGlobalSearch}>
                                        <i className="icon-magnifier" title="Search this Site"></i>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="tel:18004191055" onClick={handleTelGtag}>
                                        <i className="icon-phone gtag-topheader-telephone" title="Click to Call"></i>
                                    </a>
                                </li>
                                <li className="">
                                    <a href="support" >
                                        <img src="/images/icon/customer-service.png" alt="customer service " width="14" height="13" onClick={handleSupportGtag}/>
                                    </a>
                                </li>
                                <li className=" mail-popup-wrapper">
                                    <a href="mail:customercare@byd.com" className="mail-to-toggler" onClick={handleMailTo}>
                                        <i className="icon-envelope" title="Write Us"></i>
                                    </a>
                                    <div className="form-wrapper">
                                        <form className="">
                                            <div className="fieldset mb-20">
                                                <input type="email" value={formData.email_id} name="email_id" placeholder="Type your email ID" onChange={handleInput} />
                                                {formDataError.email_id && (<span className="withError red">Please enter valid email</span>)}
                                            </div>
                                            <div className="fieldset">
                                                <textarea value={formData.message} placeholder="Type your Message..." rows="10" name="message" onChange={handleInput}></textarea>
                                                {formDataError.message && (<span className="withError red">Please enter message</span>)}
                                            </div>
                                            <div className="fieldset mb-20">
                                                {thankyouMSG && (<span className="white">Your email has been sent!</span>)}
                                                {errorMSG && (<span className="withError red">Something went wrong. Please try again later.</span>)}
                                            </div>
                                            <button type="button" onClick={handleSend}>SEND</button>
                                        </form>
                                    </div>
                                </li>
                                <li className="">
                                    <a
                                        href="https://wa.me/917303950845?text=hello"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={handleTopheaderWhatsapp}
                                    >
                                        <FontAwesomeIcon icon={faWhatsapp} className="whatsapp" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="header">
                            <div>
                                <a href="/" className="logo">
                                    <img src="/images/logo.png" alt="Logo" className="img-fluid" />
                                </a>
                            </div>
                            <nav className="nav-link">
                                <ul>
                                    {menuData.length > 0 && menuData.map((elem, index) => {
                                        if (!elem.hasChild) {
                                            return (
                                                <li key={`navbarlink_parent_${index}`}>
                                                    <a href={`${path}${elem.url}`}>{elem.label}</a>
                                                </li>
                                            );
                                        } else {
                                            return (
                                                <li className="sub-nav-toggler" key={`navbarlink_parent_${index}`}>
                                                    <span>{elem.label}</span>
                                                    {elem.hasChild && (
                                                        <ul className="sub-nav">
                                                            {elem.child.map((chil, chilIndex) => {
                                                                if (!chil.hasChild) {
                                                                    return (
                                                                        <li key={`navbarlink_child_${chilIndex}`}>
                                                                            <a href={`${path}${chil.url}`}>{chil.label}</a>
                                                                        </li>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <li className="sub-nav-inner-toggler" key={`navbarlink_child_${chilIndex}`}>
                                                                            <span>{chil.label}</span>
                                                                            <ul className="sub-nav-inner">
                                                                                {chil.child.length > 0 && chil.child.map((itm, ind) => (
                                                                                    <li key={`navbarlink_subchild_${index}_${chilIndex}_${ind}`}>
                                                                                        <a href={`${path}${itm.url}`}>{itm.label}</a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </li>
                                                                    );
                                                                }
                                                            })}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                                <ul className="rightMenuIcon">
                                    {rightMenuData.length > 0 && rightMenuData.map((item, index) => (
                                        <li className="pl-0" key={`navbarlink_right_${index}`}>
                                            <a href={`${path}${item.url}`} className='test-drive-link' onClick={() => handleEnquiryClick(item)}>
                                                {item.iconImage && (<img src={`${configData.IMAGE_BASE_URL}${item.iconImage}`} className="img-fluid" />)}
                                                {item.label}
                                            </a>
                                        </li>
                                    ))}
                                    <li className="menu-toggler-wrapper">
                                        <button type="button" className="menu-toggler" onClick={handleMenuToggler}>
                                            <i className="icon-menu"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header;