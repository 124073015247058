import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Carousel } from 'react-carousel-minimal';
import configData from '../../../config.json';

const HomeSlider = ({ sliderId }) => {
    const [sliderData, setSliderData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    
    const isMobile = window.innerWidth < 768;
    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    };
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    };

    const getSliderData = (id) => {
        axios.get(`${configData.API_URL}slider/${id}`).then(function (response) {
            const filteredData = [];
            if (response.data.data && response.data.data.image_list) {
                response.data.data.image_list.map(item => {
                    filteredData.push({
                        image: (isMobile && item.mobileImage) ? `${configData.IMAGE_BASE_URL}${item.mobileImage}` : `${configData.IMAGE_BASE_URL}${item.webImage}`,
                        caption: item.title.trim().length > 0 ? `<div class="term-text"><p>${item.caption}</p></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="slide-title">${item.title}</p>
                                    <p class="slide-sub-title">${item.subTitle}</p>
                                    <p>
                                        ${item.buttonOneText.trim().length > 0 ? `<a class="slide-btn black-white" href="${item.buttonOneUrl}">${item.buttonOneText}<i class="icon-arrow-right"></i></a>` : ''}

                                        ${item.buttonTwoText.trim().length > 0 ? `<a class="slide-btn black-white" href="${item.buttonTwoUrl}">${item.buttonTwoText} <i class="icon-arrow-right"></i></a>` : ''}
                                    </p>
                                </div>
                            </div>
                        </div>` : '',
                    });
                });
            }
            setSliderData(filteredData);
            setLoading(false); 
        }).catch(error => {
            console.log(error);
            setLoading(false); 
        });
    };
    useEffect(() => {
        if (sliderId) {
            setLoading(true); 
            getSliderData(sliderId);
        }
    }, [sliderId]);
    return (
        <section className="fluid banner-bg">
            <div id="slides-shop" className="cover-slides">
                {loading ? (
                    <div>Loading...</div>
                ) : (
                        <Carousel
                            data={sliderData}
                            time={3000}
                            width="100%"
                            height="auto"
                            captionStyle={captionStyle}
                            radius="10px"
                            slideNumber={false}
                            slideNumberStyle={slideNumberStyle}
                            captionPosition="top"
                            automatic={true}
                            dots={false}
                            pauseIconColor="white"
                            pauseIconSize="40px"
                            slideBackgroundColor="darkgrey"
                            slideImageFit="cover"
                            thumbnails={false}
                            thumbnailWidth="100px"
                            showNavBtn={true}
                            style={{
                                textAlign: "center",
                                maxWidth: "100%",
                                maxHeight: "auto",
                                margin: "40px auto",
                            }}
                        />
                    )}
            </div>
        </section>
    );
};

export default HomeSlider;
