import React, { useState, useEffect } from 'react';
import axios from "axios";
import Slider from "react-slick";
import configData from '../../../config.json';

const SliderWithLeftCaption = ({sliderId, leftCaption, title}) => {
    const [sliderData, setSliderData] = useState([]);
    const isMobile = window.innerWidth < 768;
    const settingsMultipleSlides = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
    };

    const getSliderData = (id) => {
        axios.get(`${configData.API_URL}slider/${id}`).then(function(response) {
            const filteredData = [];
            if (response.data.data && response.data.data.image_list) {
                response.data.data.image_list.map(item => {
                    filteredData.push({
                        image: (isMobile && item.mobileImage) ? item.mobileImage : item.webImage,
                        caption: item.caption || '',
                        altText: item.subTitle
                    });
                });
            }
            setSliderData(filteredData);
        }).catch(error => {
            console.log(error);
        });
    };
    useEffect(() => {
        if(sliderId){
            getSliderData(sliderId);
        }
    }, [sliderId]);

    return (
        <section className="fluid bg-light-gray pt-10">
            <div className="container">
                <div className="row mb-20 allnewe6 align-items-center background-white relative">
                    <div className="col-xs-12 col-sm-12 col-md-12 text-center background-white pt-20">
                        <span id="exterior"></span>
                        <p className="heading-3">{title} </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 background-white pb-20" dangerouslySetInnerHTML={{__html: leftCaption}}>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 background-white pb-20 text-center" data-eventtype="1">
                        {sliderData && (<Slider {...settingsMultipleSlides}>
                            {sliderData.map((media, mIndex)=> 
                                <div key={`slider_item_5_${mIndex}`}>
                                    <div className="box">
                                        <div className="box-image">
                                            <img src={`${configData.IMAGE_BASE_URL}${media.image}`} alt={media.altText} className="img-fluid"/>
                                        </div>
                                        <p  className="text-center multiple-slide-caption mt-10" dangerouslySetInnerHTML={{__html: media.caption}}></p>
                                    </div>
                                </div>
                            )}
                        </Slider>)}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SliderWithLeftCaption;