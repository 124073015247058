import React, { useState } from "react";
import configData from "../../../config.json";

const BydeMaxSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedColor, setSelectedColor] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const slides = [
    {
      image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1728027032845.webp`,
      backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1727075678552.webp`,
      text: "Quartz Blue",
    },
    {
      image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1728027090625.webp`,
      backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1727078734765.webp`,
      text: "Cosmos Black",
    },
    {
      image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1728027123541.webp`,
      backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1727078630671.webp`,
      text: <span style={{ color: "rgb(90, 90, 90)" }}>Crystal White</span>,
    },
    {
      image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1728027175115.webp`,
      backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1727078818827.webp`,
      text: "Harbour Grey",
    },
  ];

  const colors = ["#273651", "#1C1F22", "#C6CED9", "#57595B"];

  const changeSlide = (index) => {
    setCurrentSlide(index);
    setActiveIndex(index);
    setSelectedColor(index);
  };
  return (
    <>
      <br></br>
      <br></br>
      <section className="fluid colorSection">
        <div className="slider-container">
          <div className="slider">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slide ${activeIndex === index ? "active" : ""}`}
                style={{ backgroundImage: `url(${slide.backgroundImage})` }}
              >
                <div className="text">{slide.text}</div>
                <img
                  src={slide.image}
                  alt={`Slide ${index + 1}`}
                  className="slide-image"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="input-container">
          {colors.map((color, index) => (
            <div
              key={index}
              className="color-button"
              style={{
                backgroundColor: color,
                border: index === selectedColor ? "2px solid black" : "none",
              }}
              onClick={() => changeSlide(index)}
            ></div>
          ))}
        </div>
      </section>
    </>
  );
};

export default BydeMaxSlider;
