//To create dynamic pages from cms.
const Keys = {
  HOME_SLIDER: "HOME_SLIDER",
  SLIDER_WITH_SINGLE_IMAGE: "SLIDER_WITH_SINGLE_IMAGE",
  SLIDER_WITH_MULTIPLE_IMAGE: "SLIDER_WITH_MULTIPLE_IMAGE",
  SLIDER_WITH_TOP_CAPTION: "SLIDER_WITH_TOP_CAPTION",
  SLIDER_WITH_BOTTOM_CAPTION: "SLIDER_WITH_BOTTOM_CAPTION",
  SLIDER_WITH_LEFT_CAPTION: "SLIDER_WITH_LEFT_CAPTION",
  SLIDER_WITH_RIGHT_CAPTION: "SLIDER_WITH_RIGHT_CAPTION",
  TEST_DRIVER_FORM: "TEST_DRIVER_FORM",
  NEWS: "NEWS",
  EVENT: "EVENT",
  DEALERLIST: "DEALERLIST",
  NEWSLETTER: "NEWSLETTER",
  CONTACTUSFORM: "CONTACTUSFORM",
  CAREER_LIST: "CAREER_LIST",
  BYDSEALHOMESLIDER_PAGE: "BYDSEALHOMESLIDER_PAGE",
  BYDSEAL_SLIDER: "BYDSEAL_SLIDER",
  BYDATTO_SLIDER: "BYDATTO_SLIDER",
  BYDATTOHOMESLIDER_PAGE: "BYDATTOHOMESLIDER_PAGE",
  BYDEMAXHOMESLIDER_PAGE: "BYDEMAXHOMESLIDER_PAGE",
  BYDEMAX_SLIDER: "BYDEMAX_SLIDER",
};
export default Keys;
