import React from "react";
import CountUp from 'react-countup';
import constant from '../../../../src/config.json';

const bydsealhomeimage = () => {
  return (
    <section className="fluid bg-light-gray-1">
        <div id="seal-banner">
        <div>
          <div style={{display: "flex", justifyContent: 'flex-end'}}>
            <div style={{marginTop: '2%', marginRight: 20}}>
              <img src={`${constant.IMAGE_BASE_URL}uploads/image-upload-1713959490144.png`} className="img-fluid euro-ncap" alt="Euro NCAP"/>
            </div> 
            <div style={{marginTop: '2%',marginRight: 20}}>
              <img src={`${constant.IMAGE_BASE_URL}uploads/image-upload-1713959516901.png`} className="img-fluid encap" alt="Encap"/>
            </div>
          </div>
          <div className="ima-banner-div">
            <img className="img-fluid ima-banner-img lazy" src={`${constant.IMAGE_BASE_URL}uploads/image-upload-1713959558011.png`} alt="Banner Image"/>
          </div>
        </div>

        <div className="box-container">
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp 
                  start={0}
                  end={3.8}
                  duration={5}
                  decimals={1}
                  redraw={true}
                />s*</div>
              <div className="subContent">0 - 100 km/h</div>
            </div>
          </div>
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp 
                  start={0}
                  end={0.219}
                  duration={5}
                  decimals={3}
                  redraw={true}
                />Cd
              </div>
              <div className="subContent">Aero Drag Coefficient</div>
            </div>
          </div>
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup"> 
                <CountUp 
                  start={0}
                  end={650}
                  duration={3}
                  decimals={0}
                  redraw={true}
                />km*</div>
              <div className="subContent">Top Driving range up to 650km*</div>
            </div>
          </div> 
          <div className="seal-box">
            <div className="box-content">
              <div className="box-countup">
                <CountUp 
                  start={0}
                  end={200}
                  duration={3}
                  decimals={0}
                  redraw={true}
                />km*
              </div>
              <div className="subContent">15-minute charge driving range*</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  );
};

export default bydsealhomeimage;




