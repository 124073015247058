import React, { useEffect, useState } from "react";
import axios from "axios";
import configData from "../../../config.json";

const GlobalSearch = () => {
  const [searchKey, setSearchKey] = useState("");
  const [newsList, setNewsList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const [sliderList, setSliderList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const getGlobalSearchContents = (e) => {
    const inputValue = e.target.value;
    setSearchKey(inputValue);
    const searchKeyLower = inputValue.toLowerCase();
    if (
      inputValue.length > 3 &&
      newsList.length !== 0 &&
      pageList.length !== 0
    ) {
      const filteredSearchList = [];
      const filteredPageData = pageList.filter((item) =>
        item.body.toLowerCase().includes(searchKeyLower)
      );
      filteredSearchList.push(...filteredPageData);

      newsList.forEach((elem) => {
        if (elem.body.toLowerCase().includes(searchKeyLower)) {
          filteredSearchList.push({
            ...elem,
            sku: `news/${elem.sku}`,
          });
        }
      });

      careerList.forEach((elem) => {
        const item = JSON.stringify([elem]);
        if (item.toLowerCase().includes(searchKeyLower)) {
          filteredSearchList.push({
            sku: `careers`,
            title: "Careers",
          });
        }
      });

      const matchSliderList = [];
      sliderList.forEach((elem) => {
        const item = JSON.stringify([elem]);
        if (item.toLowerCase().includes(searchKeyLower)) {
          matchSliderList.push(elem.id);
        }
      });
      const sliderPageList = [];
      if (matchSliderList.length > 0) {
        matchSliderList.forEach((elem) => {
          pageList.forEach((item) => {
            if (item.body.toLowerCase().includes(elem)) {
              sliderPageList.push(item);
            }
          });
        });
      }
      sliderPageList.forEach((elem) => {
        const pageFound = filteredSearchList.find(
          (item) => item.title.toLowerCase() === elem.title.toLowerCase()
        );
        if (pageFound === undefined) {
          filteredSearchList.push(elem);
        }
      });

      setSearchList(filteredSearchList);
    }
  };
  const getAllData = () => {
    Promise.all([
      axios.get(`${configData.API_URL}page`),
      axios.get(`${configData.API_URL}news`),
      axios.get(`${configData.API_URL}career`),
      axios.get(`${configData.API_URL}slider`),
    ]).then((resp) => {
      setPageList(resp[0].data.data);
      setNewsList(resp[1].data.data);
      setCareerList(resp[2].data.data);
      setSliderList(resp[3].data.data);
    });
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <div className="search-wrapper">
      <i className="icon-close close-search-wrapper"></i>
      <div className="popup">
        <p className="search-title">What are you looking for?</p>
        <div className="input-group search-input-wrapper">
          <i className="icon-magnifier"></i>
          <input
            type="text"
            value={searchKey}
            className="form-control"
            placeholder="Search"
            onChange={getGlobalSearchContents}
          />
        </div>
        <ul className="search-list" id="search-list">
          {searchKey.length > 3 ? (
            searchList.length > 0 ? (
              searchList.map((item, index) => (
                <li key={`global_search_list_${index}`}>
                  <a href={item.sku}>
                    {`"${searchKey}" in ${
                      item.sku.includes("news")
                        ? `News - ${item.title}`
                        : item.title
                    }`}
                  </a>
                </li>
              ))
            ) : (
              <li>
                <a>No Result Found</a>
              </li>
            )
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default GlobalSearch;
