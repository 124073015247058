import React, { useState, useEffect } from 'react';
import axios from "axios";
import Slider from "react-slick";
import configData from '../../../config.json';

const SliderWithMultipleImage = ({ sliderId }) => {
    const [sliderData, setSliderData] = useState([]);
    const isMobile = window.innerWidth < 768;
    const settingsMultipleSlides = {
        dots: false,
        infinite: true,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 1,
        speed: 500
    };

    const getExtension = (img) => {
        let imgArr = img.split('.');
        return imgArr[imgArr.length - 1];
    };

    const getSliderData = (id) => {
        axios.get(`${configData.API_URL}slider/${id}`).then(function (response) {
            const filteredData = [];
            if (response.data.data && response.data.data.image_list) {
                response.data.data.image_list.forEach(item => {
                    if (item.mobileImage && getExtension(item.mobileImage) === 'gif') {
                        filteredData.push({
                            image: item.webImage,
                            imageGif: item.mobileImage,
                            isGif: true,
                            caption: item.caption || '',
                            altText: item.subTitle
                        });
                    } else {
                        filteredData.push({
                            image: (isMobile && item.mobileImage) ? item.mobileImage : item.webImage,
                            isGif: false,
                            caption: item.caption || '',
                            altText: item.subTitle
                        });
                    }
                });
            }
            setSliderData(filteredData);
        }).catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
        if (sliderId) {
            getSliderData(sliderId);
        }
    }, [sliderId]);

    const handleImageClick = (index) => {
        setSliderData(prevData =>
            prevData.map((item, i) => {
                if (i === index && item.isGif) {
                    return {
                        ...item,
                        image: item.imageGif,
                        isGif: false
                    };
                }
                return item;
            })
        );
    };

    return (
        <section className="fluid bg-light-gray pt-10">
            <div className="cover-slides newmodel-carousel">
                {sliderData && (
                    <Slider {...settingsMultipleSlides}>
                        {sliderData.map((media, mIndex) => (
                            <div key={`slider_item_5_${mIndex}`}>
                                <div className="box">
                                    <div className="box-image">
                                        <img
                                            className="image"
                                            src={`${configData.IMAGE_BASE_URL}${media.image}`}
                                            alt={media.altText}
                                            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%', height: '100%' }}
                                            onClick={() => handleImageClick(mIndex)}
                                        />
                                        {media.isGif && (
                                            <>
                                                <img
                                                    className="playButton"
                                                    src={`${configData.IMAGE_BASE_URL}${media.image}`}
                                                    alt={media.altText}
                                                    style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, margin: 'auto', cursor: 'pointer', width: '100%', height: '100%', objectFit: 'cover' }}
                                                    onClick={() => handleImageClick(mIndex)}
                                                />
                                                <span
                                                    className="playIcon"
                                                    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer', backgroundColor: '#202020', borderRadius: '50%', padding: '20px', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    onClick={() => handleImageClick(mIndex)}
                                                >
                                                    <i className="icon-control-play"></i>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <span className="text-center multiple-slide-caption mt-10 spanMargin" dangerouslySetInnerHTML={{ __html: media.caption }}></span>
                                </div>
                            </div>
                        ))}
                    </Slider>
                )}
            </div>
        </section>
    );
};

export default SliderWithMultipleImage;
