import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios"
import configData from '../../config.json'

import Header from '../Header';
import Footer from '../Footer';
import Newsletter from '../common/newsletter/newsletter';

const Newsdetails = () => {
    const { newsurl } = useParams();
    const [cmscontents, setCmscontents] = useState([]);
    // const location = 
    useEffect(() => {
        getCmscontents();
    }, []);

    function getCmscontents() {
        axios.get(configData.API_URL+'news/'+newsurl).then(function(response) {
            setCmscontents(response.data.data);
        });
    }
    

  return (
    <>
        <Header />
        <section className="fluid">
            <div className="container">
			<ul className="breadcrumb">
                <li><a href="/">Home</a></li>
                <li><a href="/news">News</a></li>
                <li>{newsurl.replaceAll('_', ' ').substring(0, 20)}...</li>
            </ul>
            <div className="row pvb-30">
                    <div className="col-xs-12 col-sm-12 col-md-12 news-detail pv-30" dangerouslySetInnerHTML={{__html:cmscontents.body}}>
                    </div>
                </div>
            </div>
        </section>
        <section className="fluid stay-tuned-banner">
            <div className="container">
                <div className="row">
                    <Newsletter />
                </div>
            </div>
        </section>
        <HelmetProvider>
                <Helmet>
                    <title>{cmscontents.title}</title>                    
                </Helmet>
            </HelmetProvider>
        <Footer />
    </>
  );
};
export default Newsdetails;