import React, { useState } from 'react';
import configData from '../../../config.json';

const BYDSealSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedColor, setSelectedColor] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
      { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1717065996258.webp`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1713959988971.png`, text: 'Arctic Blue' },
      { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1717066951142.webp`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1713960046929.png`, text: 'Aurora White' },
      { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1717067627902.webp`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1713960091604.png`, text: 'Atlantis Gray' },
      { image: `${configData.IMAGE_BASE_URL}uploads/image-upload-1717067867929.webp`, backgroundImage: `${configData.IMAGE_BASE_URL}uploads/image-upload-1713960150881.png`, text: 'Cosmos Black' },
    ];
  
    const colors = ['#547ABD', '#B1B8C5', '#3A587C', '#242B33'];
    
  
    const changeSlide = (index) => {
      setCurrentSlide(index);
      setActiveIndex(index);
      setSelectedColor(index); 
  
    };

  return (
    <>
    <br></br>
    <br></br>
        <section className="fluid colorSection">
                <div className="slider-container">
                    <div className="slider">
                        {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`slide ${activeIndex === index ? 'active' : ''}`}
                            style={{ backgroundImage: `url(${slide.backgroundImage})` }}
                        >
                             <div className="text">{slide.text}</div> 
                            <img src={slide.image} alt={`Slide ${index + 1}`}  className="slide-image"/>
                           
                        </div>
                        ))}
                    </div>
                    </div>
                <div className="input-container">
                    {colors.map((color, index) => (
                    <div key={index} className="color-button" style={{ backgroundColor: color, border: index === selectedColor ? '2px solid black' : 'none'  }} onClick={() => changeSlide(index)}></div>
                    ))}
                </div>
        </section>
        </>
  );
};
  
export default BYDSealSlider;